










































































import Vue, { PropType } from 'vue'
import Separator from '../shared/Separator.vue'
import CurrencyInput from '../shared/CurrencyInput.vue'

export default Vue.extend({
  name: 'MoreFilters',
  components: {
    CurrencyInput,
    Separator,
  },
  props: {
    showArchived: {
      type: Boolean,
      required: true,
    },
    dateRange: {
      type: Array as PropType<Date[]>,
      required: true,
    },
    priceRange: {
      type: Object as PropType<{
        min: number
        isMinEnabled: boolean
        max: number
        isMaxEnabled: boolean
      }>,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    showArchivedValue: {
      get(): boolean {
        return this.showArchived
      },
      set(value: boolean) {
        this.$emit('updateFilter', 'showArchived', value)
      },
    },
    dateRangeValue: {
      get(): Date[] {
        return this.dateRange
      },
      set(value: Date[]) {
        this.$emit('updateFilter', 'dateRange', value)
      },
    },
    priceMin: {
      get(): number {
        return this.priceRange.min
      },
      set(value: number) {
        this.$emit('updateFilter', 'priceMin', value)
      },
    },
    isPriceMinEnabled: {
      get(): boolean {
        return this.priceRange.isMinEnabled
      },
      set(value: boolean) {
        this.$emit('updateFilter', 'isMinEnabled', value)
      },
    },
    priceMax: {
      get(): number {
        return this.priceRange.max
      },
      set(value: number) {
        this.$emit('updateFilter', 'priceMax', value)
      },
    },
    isPriceMaxEnabled: {
      get(): boolean {
        return this.priceRange.isMaxEnabled
      },
      set(value: boolean) {
        this.$emit('updateFilter', 'isMaxEnabled', value)
      },
    },
    visibilityClasses(): Record<string, boolean> | null {
      if (this.open) return null

      return {
        'translate-x-full': true,
      }
    },
  },
  methods: {
    getIcon(isOpen: boolean) {
      return isOpen ? 'chevron-up' : 'chevron-down'
    },
    resetFilters() {
      this.$emit('resetFilters')
    },
    close() {
      this.$emit('close')
    },
  },
})
