






















































































































































































































































































import debounce from 'just-debounce-it'
import mixins from 'vue-typed-mixins'
import { getElasticSearchClient } from '@/services/elasticSearch'
import OrdersTableMixin from '@/mixins/OrdersTable.mixin'
import QueryParamsMixin from '@/mixins/QueryParams.mixin'
import FilterDropdown from '../../components/shared/FilterDropdown.vue'
import MoreFilters from '@/components/orders/MoreFilters.vue'
import ExportOrdersModal from '@/components/orders/ExportOrdersModal.vue'
import { Price } from '../../../../shared/types/types'

interface Row {
  orderId: string
  createdAt: Date
  customerName: string
  grossAmount: Price
  shippingAmount: Price
  discountAmount: Price
  refundsAmount: Price
  feeAmount: Price
  totalChargebackFee: Price
  totalChargebacks: Price
  // netAmount: Price
  otherAmount: Price
  taxAmount: Price
  totalAmount: Price
  status: string
  paymentStatus: string
  fulfillmentStatus: string
  items: number
  deliveryMethod: string
}

export default mixins(OrdersTableMixin, QueryParamsMixin).extend({
  name: 'Orders',
  components: {
    FilterDropdown,
    MoreFilters,
  },
  data() {
    return {
      searchInput: '',
      searchResult: [] as Record<string, any>,
      status: ['open', 'closed'],
      paymentStatus: [],
      fulfillmentStatus: [],
      disputeStatus: [],
      riskLevel: [],
      showArchived: false,
      dateRange: [],
      priceRange: {
        min: 0,
        isMinEnabled: false,
        max: 0,
        isMaxEnabled: false,
      },
      booleanFilters: [] as string[],
      // TODO: types
      selectedOrders: [] as any[],
      disputeStatusTypes: {
        needs_response: 'Chargeback open',
        under_review: 'Chargeback submitted',
        charge_refunded: 'Chargeback accepted',
        warning_needs_response: 'Chargeback open',
        warning_under_review: 'Chargeback submitted',
        warning_closed: 'Chargeback closed',
        won: 'Chargeback won',
        lost: 'Chargeback accepted',
      },
      disputeTagTypes: {
        needs_response: 'is-warning is-light',
        under_review: 'is-info is-light',
        charge_refunded: 'is-success is-light',
        warning_needs_response: 'is-warning is-light',
        warning_under_review: 'is-info is-light',
        warning_closed: 'is-success is-light',
        won: 'is-success is-light',
        lost: 'is-success is-light',
      },
      showMoreFilters: false,
    }
  },
  computed: {
    query(): Record<string, any> {
      const query: Record<string, any> = {
        query: {
          bool: {
            filter: [],
            must: [],
          },
        },
        track_total_hits: true,
        sort: this.sort,
        size: this.itemsPerPage,
        from: (this.currentPage - 1) * this.itemsPerPage,
      }

      if (this.priceRange.isMinEnabled) {
        query.query.bool.filter.push({
          range: {
            'totalPrice.amount': {
              gte: this.priceRange.min,
            },
          },
        })
      }

      if (this.priceRange.isMaxEnabled) {
        query.query.bool.filter.push({
          range: {
            'totalPrice.amount': {
              lte: this.priceRange.max,
            },
          },
        })
      }

      if (this.search && this.search.length > 0) {
        if (this.search.match(/^[0-9]+$/)) {
          // when search input is a number, search for orderId
          query.query.bool.filter.push({
            term: { orderId: this.search },
          })
        } else if (
          this.search.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)
        ) {
          // when search input is an email, search for customer email
          query.query.bool.filter.push({
            match: {
              'customer.email.keyword': {
                query: this.search,
                fuzziness: 0,
              },
            },
          })
        } else {
          // when search input is a string, search for customer name and product title
          query.query.bool.must.push({
            match: {
              'customer.fullName': {
                query: this.search,
              },
            },
          })
          // delete query.sort
        }
      }
      if (this.showArchived) {
        query.query.bool.filter.push({
          terms: { status: ['archived'] },
        })
      } else {
        if (this.status.length > 0) {
          query.query.bool.filter.push({
            terms: { status: this.status },
          })
        }
      }
      if (this.paymentStatus.length > 0) {
        query.query.bool.filter.push({
          terms: { paymentStatus: this.paymentStatus },
        })
      }
      if (this.fulfillmentStatus.length > 0) {
        query.query.bool.filter.push({
          terms: { fulfillmentStatus: this.fulfillmentStatus },
        })
      }
      if (this.disputeStatus.length > 0) {
        query.query.bool.filter.push({
          terms: { 'dispute.status': this.disputeStatus },
        })
      }
      if (this.riskLevel.length > 0) {
        query.query.bool.filter.push({
          terms: { 'charge.risk.level': this.riskLevel },
        })
      }
      if (this.dateRange.length > 0) {
        query.query.bool.filter.push({
          range: {
            createdAt: {
              format: 'strict_date_optional_time',
              gte: this.dateRange[0],
              lte: this.dateRange[1],
            },
          },
        })
      }

      if (
        query.query.bool.filter.length === 0 &&
        query.query.bool.must.length === 0
      ) {
        query.query = {
          match_all: {},
        }
      }

      return query
    },
    nbHits(): number {
      return this.searchResult && this.searchResult.hits
        ? this.searchResult.hits.total.value
        : 0
    },
    rows(): Row[] {
      if (!this.searchResult || this.searchResult.length === 0) {
        return []
      }
      return this.searchResult.hits.hits.map((hit: Record<string, any>) => {
        const order = hit._source
        if (!order.fee || Array.isArray(order.fee)) {
          order.fee = {
            amount: 0,
            currency: order.totalPrice.currency,
          }
        }

        const row: Row = {
          orderId: order.orderId,
          createdAt: new Date(order.createdAt),
          customerName: [
            order.customer.firstName,
            order.customer.lastName,
          ].join(' '),
          discountAmount: order.totalDiscountPrice,
          feeAmount: order.fee ?? { amount: 0, currency: 'USD' },
          totalChargebacks: order.totalChargebacks ?? {
            amount: 0,
            currency: 'USD',
          },
          totalChargebackFee: order.totalChargebackFee ?? {
            amount: 0,
            currency: 'USD',
          },
          otherAmount: {
            amount:
              -order.fee.amount -
              order.totalChargebackFee.amount +
              order.totalChargebacks.amount,
            currency: order.totalPrice.currency,
          },
          // netAmount: {
          //   amount:
          //     order.originalSubTotalPrice.amount -
          //     order.totalRefund.amount -
          //     order.totalDiscountPrice.amount,

          //   // order.fee.amount -
          //   // order.totalChargebackFee.amount +
          //   // order.totalChargebacks.amount,
          //   currency: order.netPayment.currency,
          // },
          refundsAmount: order.totalRefund,
          grossAmount: order.totalPrice,
          shippingAmount: order.shippingPrice,
          taxAmount: order.taxPrice,
          totalAmount: {
            amount:
              order.totalPrice.amount -
              // order.totalDiscountPrice.amount +gcc
              order.fee.amount -
              order.totalChargebackFee.amount +
              order.totalChargebacks.amount -
              order.totalRefund.amount, // +
            // order.shippingPrice.amount +
            // order.taxPrice.amount,
            currency: order.totalPrice.currency,
          },
          status: order.status,
          paymentStatus: order.paymentStatus,
          fulfillmentStatus: order.fulfillmentStatus,
          items: order.lineItems.reduce(
            (acc: number, { quantity }: { quantity: number }) => acc + quantity,
            0
          ),
          deliveryMethod: order.shippingMethod
            ? order.shippingMethod.zone.name
            : 'N/A',
          ...(order.dispute
            ? {
                disputeStatus: order.dispute.status,
              }
            : {}),
        }
        return row
      })
    },
    booleanFilterOptions(): Record<string, string>[] {
      return [
        {
          label: 'Show Archived',
          value: 'isArchived',
        },
      ]
    },
  },
  watch: {
    searchInput: {
      immediate: true,
      handler: debounce(function () {
        //@ts-ignore
        this.search = this.searchInput.trim()
      }, 500),
    },
    query: {
      immediate: true,
      deep: true,
      handler() {
        this.fetchData()
      },
    },
  },
  methods: {
    // TODO: types
    setSelectedOrders(checkedOrders: any[]) {
      this.selectedOrders = checkedOrders
    },
    // TODO: types
    isRowSelected(a: any, b: any) {
      return a.orderId === b.orderId
    },
    updateFilter(filterName: string, filterValue: any) {
      switch (filterName) {
        case 'priceMin': {
          this.priceRange.min = filterValue
          break
        }
        case 'isMinEnabled': {
          this.priceRange.isMinEnabled = filterValue
          break
        }
        case 'priceMax': {
          this.priceRange.max = filterValue
          break
        }
        case 'isMaxEnabled': {
          this.priceRange.isMaxEnabled = filterValue
          break
        }
        default: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this[filterName] = filterValue
          break
        }
      }
    },
    openMoreFilters() {
      this.showMoreFilters = true

      this.$emit('preventScroll', true)
    },
    closeMoreFilters() {
      this.showMoreFilters = false

      this.$emit('preventScroll', false)
    },
    debouncedSearchInput: debounce(function (this: any, value: string) {
      this.search = value
    }, 500),
    async fetchData(): Promise<void> {
      const elasticSearch = getElasticSearchClient()
      const { data } = await elasticSearch.post('/orders/_search', this.query)
      this.searchResult = data
    },
    resetFilters() {
      this.showArchived = false
      this.status = []
      this.paymentStatus = []
      this.fulfillmentStatus = []
      this.disputeStatus = []
      this.riskLevel = []
      this.dateRange = []
    },
    openExportOrdersModal() {
      this.$buefy.modal.open({
        parent: this,
        component: ExportOrdersModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          elasticSearchQueryString: JSON.stringify(this.query),
        },
      })
    },
    discountThAttrs() {
      return {
        style: 'color: rgba(156, 163, 175);',
      }
    },
  },
})
