




















































import Vue from 'vue'
import ModalHeader from '@/components/shared/ModalHeader.vue'
import { exportOrders } from '@/lib/order'
import { getCurrentUser } from '@/lib/user'

export default Vue.extend({
  name: 'ExportOrdersModal',
  components: {
    ModalHeader,
  },
  props: {
    elasticSearchQueryString: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      ordersSelection: 'all',
    }
  },
  methods: {
    async handleSave() {
      this.isLoading = true

      try {
        if (this.ordersSelection === 'all') {
          const user = await getCurrentUser()

          await exportOrders(
            user.attributes.email,
            this.elasticSearchQueryString
          )
        }

        this.$emit('close')
      } catch (err) {
        console.error(err)
        this.$buefy.toast.open({
          message: `Could not export orders.`,
          type: 'is-danger',
          position: 'is-bottom',
        })
      }

      this.isLoading = false
    },
  },
})
